// ArtworkGrid.tsx
import { Center, Loader, Pagination } from '@mantine/core';

interface ArtworkGridProps {
  artworks: Work[];
  currentPage: number;
  itemsPerPage: number;
  loading: boolean;
  pageChanging: boolean;
  onPageChange: (page: number) => void;
  onViewArtwork: (artwork: Work) => void;
}

export const ArtworkGrid = ({
  artworks,
  currentPage,
  itemsPerPage,
  loading,
  pageChanging,
  onPageChange,
  onViewArtwork,
}: ArtworkGridProps) => {
  const indexOfLastArtwork = currentPage * itemsPerPage;
  const indexOfFirstArtwork = indexOfLastArtwork - itemsPerPage;
  const currentArtworks = artworks?.slice(
    indexOfFirstArtwork,
    indexOfLastArtwork
  );

  
  if (loading || pageChanging) {
    return (
      <Center className='min-h-[50vh]'>
        <Loader />
      </Center>
    );
  }

  return (
    <div className='grid grid-cols-3 gap-10 pb-32 relative'>
      {currentArtworks?.map((artwork, index) => (
        <ArtworkCard
          key={index}
          artwork={artwork}
          onView={() => onViewArtwork(artwork)}
        />
      ))}

      <div className='absolute bottom-10 md:right-5 right-2'>
        <Pagination
          total={Math.ceil(artworks?.length / itemsPerPage)}
          value={currentPage}
          onChange={onPageChange}
          color='#DA3400'
          radius='lg'
          mt={30}
          px={0}
          styles={{
            control: {
              margin: '6px',
            },
          }}
        />
      </div>
    </div>
  );
};

// ArtworkCard.tsx
interface ArtworkCardProps {
  artwork: Work;
  onView: () => void;
}

export const ArtworkCard = ({ artwork, onView }: ArtworkCardProps) => {
  return (
    <div className='mt-1 p-4 bg-white rounded-lg shadow-[0_2px_40px_-1px_rgba(0,0,0,0.1)]'>
      <img
        src={artwork.previewImage}
        className='w-full h-64 object-cover rounded-md'
        alt={artwork.title}
      />
      <h1 className='mt-2 text-md font-bold'>
        {artwork.artist?.firstName?.toUpperCase()}{' '}
        {artwork.artist?.lastName?.toUpperCase()}
      </h1>
      <h1 className='mt-2'>{artwork.title}</h1>
      <h1 className='mt-2'>{artwork.categories}</h1>
      <h1 className='mt-2'>{artwork.materials.join(', ')}</h1>
      <h1 className='mt-2'>
        {artwork.dimensionLengthInCM}" x {artwork.dimensionWidthInCM}"
      </h1>
      <div className='flex justify-end'>
        <button
          onClick={onView}
          className='rounded-md py-1 px-5 bg-[#DA3400] text-white'>
          View
        </button>
      </div>
    </div>
  );
};

// ArtworkForm.tsx
import {
  Avatar,
  LoadingOverlay,
  Select,
  TagsInput,
  TextInput,
} from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { FaCamera, FaPlus, FaTimes } from 'react-icons/fa';
import { useRef } from 'react';

interface ArtworkFormProps {
  form: UseFormReturnType<any>;
  isLoading: boolean;
  isEditing: boolean;
  artists: Artist[];
  previewImage: string | null;
  additionalImages: string[];
  onPreviewImageUpload: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onAdditionalImagesUpload: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onRemoveAdditionalImage: (index: number) => void;
  onSubmit: () => void;
}

export const ArtworkForm = ({
  form,
  isLoading,
  isEditing,
  artists,
  previewImage,
  additionalImages,
  onPreviewImageUpload,
  onAdditionalImagesUpload,
  onRemoveAdditionalImage,
  onSubmit,
}: ArtworkFormProps) => {
  const fileInputRefPreview = useRef<HTMLInputElement>(null);
  const fileInputRefOthers = useRef<HTMLInputElement>(null);

  return (
    <div className='w-4/5 mt-12 mx-5 p-6 mb-20 bg-white rounded-lg shadow-[0_2px_40px_-1px_rgba(0,0,0,0.1)]'>
      <LoadingOverlay
        visible={isLoading}
        zIndex={1000}
        overlayProps={{ radius: 'sm', blur: 2 }}
        loaderProps={{ color: 'red', type: 'bars' }}
      />
      <form onSubmit={form.onSubmit(onSubmit)}>
        {/* Preview Image Section */}
        <div className='mb-4'>
          <div className='flex justify-around text-center mb-10'>
            <div className='mx-auto justify-center'>
              <Avatar
                size='xl'
                src={previewImage || ''}
                alt='preview Image Work'
                className='mx-auto cursor-pointer'
                onClick={() => fileInputRefPreview.current?.click()}>
                <FaCamera />
              </Avatar>
              <p className='text-sm text-[#DA3400] mt-1'>
                Upload Artist Preview Image
              </p>
            </div>
          </div>
          <input
            ref={fileInputRefPreview}
            type='file'
            accept='image/*'
            onChange={onPreviewImageUpload}
            className='hidden'
          />
        </div>

        {/* Form Fields */}
        <div className='grid grid-cols-2 gap-y-8 gap-x-5'>
          <Select
            label='Artist Name'
            placeholder='Select Artist'
            data={artists.map((artist) => ({
              value: artist._id,
              label: `${artist?.firstName} ${artist?.lastName}`,
            }))}
            {...form.getInputProps('artistName')}
          />
          <TextInput
            label='Artwork Title'
            placeholder='Artwork Title'
            {...form.getInputProps('title')}
          />
          <TextInput
            label='Year of Creation'
            type='date'
            placeholder='Year of Creation'
            {...form.getInputProps('year')}
          />
          <Select
            label='Artwork Category'
            placeholder='Select categories'
            data={['single', 'mixed media']}
            {...form.getInputProps('categories')}
          />
          <TagsInput
            label='Artwork Material'
            placeholder='Enter materials'
            data={[]}
            {...form.getInputProps('materials')}
          />
          <TextInput
            label='Artwork Dimension (Length)'
            type='number'
            placeholder='Artwork Dimension (Length)'
            {...form.getInputProps('dimensionLengthInCM')}
          />
          <TextInput
            label='Artwork Dimension (Width)'
            type='number'
            placeholder='Artwork Dimension (Width)'
            {...form.getInputProps('dimensionWidthInCM')}
          />
        </div>

        {/* Additional Images Section */}
        <div className='mt-8'>
          <small>Additional Images</small>
          <div className='flex flex-wrap gap-5 mt-2'>
            {additionalImages.map((image, index) => (
              <div
                key={index}
                className='relative shadow-lg border-[1px] px-4 py-3 rounded-md'>
                <img
                  src={image}
                  alt={`Additional Image ${index + 1}`}
                  className='w-20 h-20 object-cover rounded-full'
                />
                <button
                  type='button'
                  onClick={() => onRemoveAdditionalImage(index)}
                  className='absolute -top-2 -right-2 bg-red-500 text-white rounded-full p-1'>
                  <FaTimes size={12} />
                </button>
              </div>
            ))}
            <div className='shadow-lg border-[1px] px-4 py-2 rounded-md'>
              <Avatar
                size='lg'
                src=''
                alt='Upload New Image'
                className='mx-auto cursor-pointer'
                onClick={() => fileInputRefOthers.current?.click()}>
                <FaPlus />
              </Avatar>
              <p className='text-xs text-[#DA3400] mt-1'>Add Image</p>
            </div>
          </div>
          <input
            ref={fileInputRefOthers}
            type='file'
            accept='image/*'
            multiple
            onChange={onAdditionalImagesUpload}
            className='hidden'
          />
        </div>

        {/* Submit Button */}
        <div className='flex justify-end mt-10'>
          <button
            type='submit'
            className='px-5 mt-5 bg-[#DA3400] text-white py-3 rounded-lg font-bold'>
            {isEditing ? 'Edit Artwork' : 'Create Artwork'}
          </button>
        </div>
      </form>
    </div>
  );
};
