import { notifications } from '@mantine/notifications';
import { apiDeleteArtwork } from '../../../api/ApiServices';
import { useNavigate } from 'react-router-dom';
import { Modal } from '@mantine/core';
import React from 'react';
import { RiCloseLargeLine } from 'react-icons/ri';

interface ArtworkDetailProps {
  artwork: Artist | Work;
  selectedImage: string;
  edit: any;
  onImageSelect: (img: string) => void;
}

const ArtworkDetail: React.FC<ArtworkDetailProps> = ({
  artwork,
  selectedImage,
  onImageSelect,
  edit,
}) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  
  const deleteArtwork = async (id: string) => {
    const isConfirmed = window.confirm(
      'Are you sure you want to delete this artwork?'
    );
    if (!isConfirmed) {
      notifications.show({
        title: 'Cancel',
        mt: '100px',
        color: 'blue',
        message: 'You have successfully Cancel the action to delete  ',
      });
      return;
    }
    try {
      const resp = await apiDeleteArtwork(id);
      notifications.show({
        title: 'Successful',
        mt: '100px',
        color: 'green',
        message: 'You have successfully deleted the Artwork ',
      });
      navigate(0);
    } catch (error: any) {
      notifications.show({
        title: 'Something went wrong',
        color: 'red',
        mt: '100px',
        message: error?.message || 'An error occurred, please try again later.',
      });
    }
  };

  return (
    <>
      <div className='grid grid-cols-2 gap-10 h-[50.5rem] mb-10 p-6 bg-white rounded-lg shadow-[0_2px_40px_-1px_rgba(0,0,0,0.1)]'>
        <div className='mt-5 relative'>
          <h2 className='text-2xl font-bold mb-3'>
            {artwork.title}, {new Date(artwork.year).getFullYear()}
          </h2>

          <p className='text-lg'> {artwork.categories.join(', ')}</p>
          <p className='text-lg mb-1'> {artwork.materials.join(', ')}</p>
          <p>
            {artwork.dimensionLengthInCM}" x {artwork.dimensionWidthInCM}"
          </p>
          <div className=' mt-4 absolute bottom-6'>
            <h3 className='text-lg font-semibold mb-2'>FURTHER IMAGES</h3>
            <div className='grid grid-cols-3 gap-4'>
              {[artwork.previewImage, ...artwork.images].map((img, index) => (
                <img
                  key={index}
                  src={img}
                  alt={`${artwork.title} - ${index + 1}`}
                  className='w-[130px] h-[130px] cursor-pointer'
                  onClick={() => onImageSelect(img)}
                />
              ))}
            </div>
            <div className='flex gap-x-5 mt-6'>
              <button
                className='px-8 bg-[#DA3400] text-white py-2 rounded-md font-bold'
                onClick={edit}>
                Edit Artwork
              </button>
              <button
                className='rounded-md px-8 bg-transparent border border-[#DA3400] text-[#DA3400]'
                onClick={() => deleteArtwork(artwork._id)}>
                Delete Artwork
              </button>
            </div>
          </div>
        </div>
        <div className='mt-5'>
          <img
            src={selectedImage}
            alt={artwork.title}
            className='w-full h-[84vh] cursor-zoom-in'
            onClick={() => setIsModalOpen(true)}
          />
        </div>
      </div>

      <Modal
        opened={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        size='100%'
        fullScreen
        transitionProps={{ transition: 'fade', duration: 200 }}
        overlayProps={{
          color: '#000',
          opacity: 1.65,
          blur: 3,
        }}
        withCloseButton={false}
        styles={{
          content: {
            background: 'transparent',
          },
        
       
        }}>
        <button
          onClick={() => setIsModalOpen(false)}
          className='absolute end-6 top-28 z-[1001]'>
          <RiCloseLargeLine size={32} color='white' />
        </button>
        <div className='flex items-center justify-center w-full h-screen mt-20'>
          <img
            src={selectedImage}
            alt={artwork.title}
            className='max-w-[95%] max-h-[95vh] object-contain'
            style={{ margin: 'auto' }}
          />
        </div>
      </Modal>
    </>
  );
};
export default ArtworkDetail;
