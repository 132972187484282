import { useEffect, useRef, useState } from "react";
import style from "../../styles/style.module.css";
import {
  Avatar,
  Loader,
  LoadingOverlay,
  MultiSelect,
  Select,
  TextInput,
  Textarea,
} from "@mantine/core";

import dayjs from "dayjs";
import { FaArrowLeft, FaCamera, FaPlus } from "react-icons/fa6";
import { FaTimes } from "react-icons/fa";
import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import {
  getPresignedUrl,
  apiCreateExhb,
  apiAllGetExhb,
  apiArtistExhb,
  apiGetAllArtist,
} from "../../api/ApiServices";
import ExhbDetails from "../artist/details/ExhbDetails";

interface FormValues {
  artistName: string[];
  title: string;
  startDate: string;
  endDate: string;
  description: string;
  type: string;
  subTitle: string;
}
const Index = () => {
  const [artistExhb, setArtistExhb] = useState<Exhibition[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [isFormView, setIsFormView] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentExhbPage] = useState(1);
  const [upcomingExhbPage] = useState(1);
  const itemsPerPage = 1;
  const [editingExhb, setEditingExhb] = useState(false);
  const [previewImageExhibition, setpreviewImageExhibition] = useState<
    string | null
  >(null);
  const [additionalImages, setAdditionalImages] = useState<string[]>([]);
  const [isExhbDetails, setIsExhbDetails] = useState(false);
  const [selectedExhibition, setSelectedExhibition] =
    useState<Exhibition | null>(null);
  const [artist, setArtists] = useState<Artist[]>([]);

  const createModal = () => {
    setIsFormView(true);
  };
  const editModal = () => {
    setIsFormView(true);
    setEditingExhb(true);
  };
  const fetchAllArtists = async () => {
    setLoading(true);
    try {
      const resp = await apiGetAllArtist();
      setArtists(resp.artists);
      setLoading(false);
    } catch (error: any) {
      notifications.show({
        title: "Something went wrong",
        color: "red",
        mt: "100px",
        message: error?.message || "An error occurred, please try again later.",
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllArtists();
  }, []);

  const allExhb = async () => {
    setLoading(true);
    try {
      const resp = await apiAllGetExhb();
      // Sort exhibitions by createdAt in descending order
      const sortedExhibitions = resp.exhibitions.sort((a, b) => {
        return (
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
      });
      setArtistExhb(sortedExhibitions);
      setLoading(false);
    } catch (error: any) {
      notifications.show({
        title: "Something went wrong",
        color: "red",
        mt: "100px",
        message: error?.message || "An error occurred, please try again later.",
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    allExhb();
  }, []);

  const filteredExhbByStatus = (status: "current" | "upcoming" | "past") => {
    const currentDate = new Date();
    return (
      artistExhb?.filter((exhibition) => {
        const startDate = new Date(exhibition.startDate);
        const endDate = new Date(exhibition.endDate);
        if (status === "current") {
          return startDate <= currentDate && endDate >= currentDate;
        } else if (status === "upcoming") {
          return startDate > currentDate;
        }
        return endDate < currentDate;
      }) || []
    );
  };

  const RenderExhibitions = ({
    status,
  }: {
    status: "current" | "upcoming";
  }) => {
    const exhibitions = filteredExhbByStatus(status);
    const currentPage =
      status === "current" ? currentExhbPage : upcomingExhbPage;

    return (
      <div>
        {exhibitions.length > 0 ? (
          <div key={status} className="md:p-0 p-3 md:mt-20 mt-12">
            <h1 className="font-medium mb-2">
              {status.toUpperCase()} EXHIBITIONS
            </h1>
            <div className="md:space-y-10">
              {exhibitions.length > 0 ? (
                exhibitions.map((exhibition) => (
                  <div
                    key={exhibition._id}
                    className="md:grid grid-cols-3 gap-10 relative"
                  >
                    <div>
                      <img
                        className="md:w-[450px] md:h-[350px] w-[390px] h-[280px]"
                        src={exhibition.previewImageExhibition}
                        alt={exhibition.title}
                        loading="lazy"
                      />
                    </div>
                    <div className="font-medium mt-2 md:my-auto mb-16">
                      <h1>
                        {exhibition.artists.map((artist, index) => (
                          <span
                            key={artist._id}
                            className="mt-1 mb-2 font-semibold text-xl"
                          >
                            {artist?.firstName} {artist?.lastName}
                            {index < exhibition.artists.length - 1 ? " x " : ""}
                          </span>
                        ))}
                      </h1>
                      <h1 className="font-bold">
                        {exhibition.title}
                      </h1>
                      <h3 className="text-sm mt-2">{exhibition.subTitle}</h3>
                      <p className="">{exhibition.type}</p>
                      <p className="my-3">
                        {dayjs(exhibition.startDate).format("D MMM")} -{" "}
                        {dayjs(exhibition.endDate).format("D MMM, YYYY")}
                      </p>
                      <div className="absolute md:bottom-0 -bottom-20">
                        <button
                          onClick={() => viewExhbDetails(exhibition)}
                          className="rounded-md py-1 px-5 bg-[#DA3400] text-white"
                        >
                          View
                        </button>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p className="text-[##DA3400]">
                  No {status} exhibitions available
                </p>
              )}
            </div>
          </div>
        ) : null}
      </div>
    );
  };
  const form = useForm<FormValues>({
    initialValues: {
      artistName: [],
      title: "",
      startDate: "",
      endDate: "",
      description: "",
      type: "",
      subTitle: "",
    },
    validate: {
      title: (value) => (value.trim() ? null : "Exhibition Title is required"),
      startDate: (value) => (value.trim() ? null : "Start Date is required"),
      endDate: (value) => (value.trim() ? null : "End Date is required"),
      description: (value) => (value ? null : "Description is required"),
      subTitle: (value) => {
        if (!value || value.trim() === "") {
          return null;
        }
      },
      type: (value) =>
        value.length > 0 ? null : "At least one categories is required",
    },
  });
  useEffect(() => {
    if (selectedExhibition) {
      form.setValues({
        artistName:
          selectedExhibition.artists.map((artist) => artist._id) || [],
        title: selectedExhibition.title || "",
        startDate: selectedExhibition.startDate
          ? dayjs(selectedExhibition.startDate).format("YYYY-MM-DD")
          : "",
        endDate: selectedExhibition.endDate
          ? dayjs(selectedExhibition.endDate).format("YYYY-MM-DD")
          : "",
        description: selectedExhibition.description || "",
        type: selectedExhibition.type || "",
      });
      if (selectedExhibition.previewImageExhibition) {
        setpreviewImageExhibition(selectedExhibition.previewImageExhibition);
        setFiles((prev) => ({
          ...prev,
          preview: selectedExhibition.previewImageExhibition,
        }));
      }

      // Set the additional images
      if (selectedExhibition.images && selectedExhibition.images.length > 0) {
        setAdditionalImages(selectedExhibition.images);
        setFiles((prev) => ({
          ...prev,
          additional: selectedExhibition.images,
        }));
      }
    }
  }, [selectedExhibition]);

  const fileInputRefPreview = useRef<HTMLInputElement>(null);
  const fileInputRefOthers = useRef<HTMLInputElement>(null);

  const handlepreviewImageExhibitionUpload = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        if (e.target?.result && typeof e.target.result === "string") {
          setpreviewImageExhibition(e.target.result);
        }
      };
      reader.readAsDataURL(file);
      uploadImage(file, "preview");
    }
  };

  const handleOtherImages = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      for (const file of Array.from(files)) {
        try {
          await uploadImage(file, "additional");
        } catch (error) {
          console.error("Error uploading image:", error);
          notifications.show({
            title: "Upload Error",
            color: "red",
            message: "An error occurred while uploading the image.",
          });
        }
      }
    }
  };

  const removeAdditionalImage = (index: number) => {
    setAdditionalImages((prev) => prev.filter((_, i) => i !== index));
    setFiles((prev) => ({
      ...prev,
      additional: prev.additional.filter((_, i) => i !== index),
    }));
  };

  const [files, setFiles] = useState<{
    preview: string;
    additional: string[];
  }>({
    preview: selectedExhibition?.previewImageExhibition || "",
    additional: selectedExhibition?.images || [],
  });

  const uploadImage = async (file: File, type: "preview" | "additional") => {
    setIsLoading(true);
    try {
      const resp = await getPresignedUrl(file.name);
      const uploadResponse = await fetch(resp.url, {
        method: "PUT",
        body: file,
        headers: {
          "Content-Type": file.type,
        },
      });
      if (!uploadResponse.ok) {
        notifications.show({
          title: "Something went wrong",
          color: "red",
          mt: "100px",
          message: "An error occurred, please try again later.",
        });
        return;
      }
      if (type === "preview") {
        setFiles((prev) => ({
          ...prev,
          preview: resp.fileName,
        }));
        setpreviewImageExhibition(URL.createObjectURL(file));
      } else {
        setFiles((prev) => ({
          ...prev,
          additional: [...prev.additional, resp.fileName],
        }));
        setAdditionalImages((prev) => [...prev, URL.createObjectURL(file)]);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const closeModal = () => {
    setIsFormView(false);
    form.reset();
    setEditingExhb(false);
    setAdditionalImages([]);
    setpreviewImageExhibition(null);
    setIsExhbDetails(false);

    form.reset();
    setFiles({ preview: "", additional: [] });
  };
  const handleSubmit = async () => {
    const validationResult = form.validate();
    if (validationResult.hasErrors) {
      console.log("Form has errors", validationResult.errors);
      return;
    }

    if (!files.preview || files.additional.length === 0) {
      notifications.show({
        title: "Missing Images",
        color: "red",
        mt: "100px",
        message: "Please upload both preview and additional images.",
      });
      return;
    }

    setIsLoading(true);
    try {
      const payload: any = {
        ...form.values,
        artistIds: form.values.artistName,
        previewImageExhibition: files.preview,
        images: files.additional,
      };

      if (editingExhb && selectedExhibition) {
        // Editing existing exhibition
        const originalImages = selectedExhibition.images || [];
        const originalArtistIds =
          selectedExhibition.artists.map((artist) => artist._id) || [];

        payload._id = selectedExhibition._id;
        payload.addedImages = files.additional.filter(
          (img) => !originalImages.includes(img)
        );
        payload.removedImages = originalImages.filter(
          (img) => !files.additional.includes(img)
        );
        payload.addedArtistIds = form.values.artistName.filter(
          (id) => !originalArtistIds.includes(id)
        );
        payload.removedArtistIds = originalArtistIds.filter(
          (id) => !form.values.artistName.includes(id)
        );
        const response = await apiArtistExhb(selectedExhibition._id, payload);
      } else {
        const response = await apiCreateExhb(payload);
      }

      setIsLoading(false);
      notifications.show({
        title: editingExhb ? "Exhibition Edited" : "Exhibition Created",
        color: "green",
        mt: "100px",
        message: editingExhb
          ? "Exhibition successfully Edited"
          : "Exhibition Successfully Created",
      });
      closeModal();
      allExhb();
    } catch (error: any) {
      notifications.show({
        title: "Something went wrong",
        color: "red",
        mt: "100px",
        message: error?.message || "An error occurred, please try again later.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const viewExhbDetails = (exhibition: Exhibition) => {
    setSelectedExhibition(exhibition);
    setIsExhbDetails(true);
  };

  return (
    <section className={style.main}>
      <div className="flex justify-between items-center relative">
        <div className="flex items-center ">
          {isFormView || editingExhb || isExhbDetails ? (
            <button
              className="mr-4 text-xs font-normal flex"
              onClick={closeModal}
            >
              <span className="my-auto ml- mr-2">
                <FaArrowLeft />
              </span>
              Go Back
            </button>
          ) : null}

          <h1 className="font-semibold">
            {isExhbDetails || isFormView ? (
              <>
                <span className="text-sm font-normal">Exhibtions </span> /{" "}
                {editingExhb ? "Edit Exhibition" : "Add Exhibition"}
              </>
            ) : (
              "All Exhibtions"
            )}
          </h1>
        </div>
        {isExhbDetails ? null : (
          <button
            className="rounded-md py-2 px-5 bg-[#DA3400] text-white"
            onClick={createModal}
          >
            Add Exhibition
          </button>
        )}
      </div>
      {loading ? (
        <div className="flex justify-center text-center items-center h-[80vh] my-auto">
          <Loader size={40} />
        </div>
      ) : isFormView ? (
        <div className="w-4/5 mt-12 mx-5 mb-20 p-6 bg-white rounded-lg shadow-[0_2px_40px_-1px_rgba(0,0,0,0.1)]">
          <LoadingOverlay
            visible={isLoading}
            zIndex={1000}
            overlayProps={{ radius: "sm", blur: 2 }}
            loaderProps={{ color: "red", type: "bars" }}
          />
          <form onSubmit={form.onSubmit(handleSubmit)}>
            <div className="mb-4">
              <div className="flex justify-around text-center mb-10">
                <div className="mx-auto justify-center">
                  <Avatar
                    size="lg"
                    src={previewImageExhibition || ""}
                    alt="preview Image Work"
                    className="mx-auto cursor-pointer"
                    onClick={() => fileInputRefPreview.current?.click()}
                  >
                    <FaCamera />
                  </Avatar>
                  <p className="text-sm text-[#DA3400] mt-1">
                    Upload Preview Image
                  </p>
                </div>
              </div>
              <input
                ref={fileInputRefPreview}
                name="previewImageExhibition"
                id="previewImageExhibitionUpload"
                type="file"
                accept="image/*"
                onChange={handlepreviewImageExhibitionUpload}
                className="hidden cursor-pointer"
              />
            </div>
            <div className="grid grid-cols-2 gap-y-8  gap-x-5">
              <MultiSelect
                label="Artist Name"
                data={artist.map((artist) => ({
                  value: artist._id,
                  label: `${artist?.firstName} ${artist?.lastName}`,
                }))}
                {...form.getInputProps("artistName")}
              />

              <TextInput
                label="Exhibition Start Date"
                type="date"
                {...form.getInputProps("startDate")}
              />
              <TextInput
                label="Exhibition End Date"
                type="date"
                {...form.getInputProps("endDate")}
              />
              <TextInput
                label="Exhibition Title"
                placeholder="Exhibition Title"
                {...form.getInputProps("title")}
              />

              <Textarea
                minRows={6}
                autosize
                label="About Exhibition"
                placeholder="About Exhibition..."
                {...form.getInputProps("description")}
              />
              <div className="flex flex-col space-y-8">
                <Select
                  label="Exhibition Category"
                  placeholder="Select categories"
                  data={["solo", "collaborative"]}
                  {...form.getInputProps("type")}
                />
                <TextInput
                  label="Sub Title (Optional)"
                  placeholder="Sub Title "
                  {...form.getInputProps("subTitle")}
                />
              </div>
            </div>
            <div className="mt-8">
              <small>Exhibition Image</small>
              <div className="flex flex-wrap gap-5 mt-2">
                {additionalImages.map((image, index) => (
                  <div
                    key={index}
                    className="relative shadow-lg border-[1px] px-4 py-2 rounded-md"
                  >
                    <img
                      src={image}
                      alt={`Additional ${index + 1}`}
                      className="w-20 h-20 object-cover rounded-full"
                    />
                    <button
                      type="button"
                      onClick={() => removeAdditionalImage(index)}
                      className="absolute -top-2 -right-2 bg-red-500 text-white rounded-full p-1"
                    >
                      <FaTimes size={12} />
                    </button>
                    {/* <p className='text-xs text-[#DA3400] mt-1 text-center'>
                      Change Image
                    </p> */}
                  </div>
                ))}
                <div className="shadow-lg border-[1px] px-4 py-2 rounded-md">
                  <Avatar
                    size="lg"
                    src=""
                    alt="Upload New Image"
                    className="mx-auto cursor-pointer"
                    onClick={() => fileInputRefOthers.current?.click()}
                  >
                    <FaPlus />
                  </Avatar>
                  <p className="text-xs text-[#DA3400] mt-1">Add Image</p>
                </div>
              </div>
              <input
                ref={fileInputRefOthers}
                name="images"
                id="otherImageUpload"
                type="file"
                accept="image/*"
                multiple
                onChange={handleOtherImages}
                className="hidden"
              />
            </div>
            <div className="flex justify-end mt-16">
              <button
                type="submit"
                disabled={isLoading}
                className={`px-5 mt-5 bg-[#DA3400] text-white py-3 rounded-lg font-bold ${
                  isLoading ? "cursor-disable" : "cursor-pointer"
                }`}
              >
                {editingExhb ? "Save Exhibition" : "Create Exhibition"}
              </button>
            </div>
          </form>
        </div>
      ) : isExhbDetails && selectedExhibition ? (
        <ExhbDetails
          exhibition={selectedExhibition}
          close={closeModal}
          edit={editModal}
          reload={allExhb}
        />
      ) : artistExhb.length === 0 ? (
        <>
          <div className="flex justify-center h-[80vh] my-auto ">
            <div className="my-auto text-center">
              <p className="font-bold text-xl mb-3 mx-auto">
                No exhibitions created yet.
              </p>
              <button
                onClick={createModal}
                className="px-40 bg-[#DA3400] text-white py-2 rounded-lg font-bold"
              >
                Add exhibitions
              </button>
            </div>
          </div>
        </>
      ) : (
        <div className="cursor-pointer mb-20">
          <RenderExhibitions status="current" />
          <RenderExhibitions status="upcoming" />
          <div className="md:p-0 p-3 md:mt-20 mt-12">
            <h1 className="font-medium">PAST EXHIBITIONS</h1>
            <div className="grid grid-cols-3 gap-8 mt-5 w-auto ">
              {filteredExhbByStatus("past").length > 0 ? (
                filteredExhbByStatus("past").map((exhibition) => (
                  <div
                    key={exhibition._id}
                    className="md:grid gap-3 bg-white shadow-[0_2px_40px_-1px_rgba(0,0,0,0.1)] rounded-lg  p-4 "
                  >
                    <img
                      className="w-auto h-[300px] rounded-lg "
                      src={exhibition.previewImageExhibition}
                      alt={exhibition.type}
                      loading="lazy"
                    />
                    <div className="font-medium mt-2 md:mt-0">
                      <h1>
                        {exhibition.artists.map((artist, index) => (
                          <span
                            className="mt-1 mb-2 font-semibold "
                            key={artist._id}
                          >
                            {artist?.firstName} {artist?.lastName}
                            {index < exhibition.artists.length - 1 ? " x " : ""}
                          </span>
                        ))}
                      </h1>
                      <h1 className="font-bold">
                        {exhibition.title}
                      </h1>
                      <h3 className="text-sm mt-2">{exhibition.subTitle}</h3>
                      <p className="my-2">{exhibition.type}</p>
                      <p className="mt-2 mb-4">
                        {dayjs(exhibition.startDate).format("D MMM")} -{" "}
                        {dayjs(exhibition.endDate).format("D MMM, YYYY")}
                      </p>
                      <button
                        onClick={() => viewExhbDetails(exhibition)}
                        className="rounded-md py-1 px-5 bg-[#DA3400] text-white"
                      >
                        View
                      </button>
                    </div>
                  </div>
                ))
              ) : (
                <p className="text-[##DA3400]">
                  No past exhibitions available.
                </p>
              )}
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default Index;
