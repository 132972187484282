import { useNavigate, useParams } from 'react-router-dom';
import style from '../../../styles/style.module.css';
import { Tabs, Avatar, LoadingOverlay } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useEffect, useRef, useState } from 'react';
import { notifications } from '@mantine/notifications';
import {
  apiProfile,
  apiUpdateArtistWorkName,
  getPresignedUrl,
} from '../../../api/ApiServices';
import { FaArrowLeft } from 'react-icons/fa6';
import ArtworkDetail from './ArtworkDetail';
import Exhibitions from './Exhibition';
import Profile from './Profile';
import dayjs from 'dayjs';
import { useArtwork } from '../../../utils/useArtwork';
import { ArtworkForm, ArtworkGrid } from '../../../utils/ArtworkGrid';

const ArtistDetails = () => {
  const { id } = useParams();
  const [isFormView, setIsFormView] = useState(false);
  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const [additionalImages, setAdditionalImages] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [viewWork, setViewWork] = useState(false);
  const [artworkImages, setArtworkImages] = useState<string[]>([]);
  const [selectedArtwork, setSelectedArtwork] = useState<Work>();
  const [selectedImage, setSelectedImage] = useState<string>('');
  const [profileImage, setProfileImage] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(9);
  const [editingArtwork, setEditingWork] = useState(false);
  const [artworkDetail, setArtworkDetail] = useState(false);
  const [pageChanging, setPageChanging] = useState(false);

  // Change page
  const handlePageChange = (newPage: number) => {
    setPageChanging(true);
    setCurrentPage(newPage);
    setTimeout(() => {
      setPageChanging(false);
    }, 500);
  };

  const {
    loading,
    artistWork,
    artists,
    artistExhb,
    singleArtist,
    createArtwork,
    editArtwork,
    getSingleArtist,
    fetchAllArtists,
  } = useArtwork();

  useEffect(() => {
    getSingleArtist(id as string);
    fetchAllArtists();
  }, []);

  const navigate = useNavigate();
  const goback = () => {
    navigate(-1);
  };
  const createModal = () => {
    setIsFormView(true);
  };
  const editModal = () => {
    setIsFormView(true);
    setEditingWork(true);
  };
  const form = useForm({
    initialValues: {
      artistName: '',
      title: '',
      year: '',
      categories: '',
      materials: [] as string[],
      dimensionLengthInCM: '',
      dimensionWidthInCM: '',
    },
    validate: {
      artistName: (value) => (value.trim() ? null : 'Artist Name is required'),
      title: (value) => (value.trim() ? null : 'Artwork Title is required'),
      year: (value) => (value.trim() ? null : 'Year of Creation is required'),
      categories: (value) =>
        value.length > 0 ? null : 'At least one category is required',
      materials: (value) =>
        value.length > 0 ? null : 'At least one material is required',
      dimensionLengthInCM: (value) =>
        value ? null : 'Artwork Dimension (Length) is required',
      dimensionWidthInCM: (value) =>
        value ? null : 'Artwork Dimension (Width) is required',
    },
  });

  useEffect(() => {
    if (selectedArtwork) {
      form.setValues({
        artistName: id || '',
        title: selectedArtwork.title || '',
        year: selectedArtwork.year
          ? dayjs(selectedArtwork.year).format('YYYY-MM-DD')
          : '',
        categories: selectedArtwork.categories[0] || '',
        materials: selectedArtwork.materials || [],
        dimensionLengthInCM: selectedArtwork.dimensionLengthInCM || '',
        dimensionWidthInCM: selectedArtwork.dimensionWidthInCM || '',
      });
      if (selectedArtwork.previewImage) {
        setPreviewImage(selectedArtwork.previewImage);
        setFiles((prev) => ({
          ...prev,
          preview: selectedArtwork.previewImage,
        }));
      }
      // Set the additional images
      if (selectedArtwork.images && selectedArtwork.images.length > 0) {
        setAdditionalImages(selectedArtwork.images);
        setFiles((prev) => ({
          ...prev,
          additional: selectedArtwork.images,
        }));
      }
    }
  }, [selectedArtwork]);

  const fileInputRefBio = useRef<HTMLInputElement>(null);

  const handlePreviewImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        if (e.target?.result && typeof e.target.result === 'string') {
          setPreviewImage(e.target.result);
        }
      };
      reader.readAsDataURL(file);
      uploadImage(file, 'preview');
    }
  };

  const handleProfileImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        if (e.target?.result && typeof e.target.result === 'string') {
          setProfileImage(e.target.result);
        }
      };
      reader.readAsDataURL(file);
      uploadImage(file, 'profile');
    }
  };

  const handleOtherImages = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      for (const file of Array.from(files)) {
        try {
          await uploadImage(file, 'additional');
        } catch (error) {
          console.error('Error uploading image:', error);
          notifications.show({
            title: 'Upload Error',
            color: 'red',
            message: 'An error occurred while uploading the image.',
          });
        }
      }
    }
  };

  const uploadImage = async (
    file: File,
    type: 'preview' | 'additional' | 'profile'
  ) => {
    setIsLoading(true);
    try {
      const resp = await getPresignedUrl(file.name);
      console.log(resp);
      const uploadResponse = await fetch(resp.url, {
        method: 'PUT',
        body: file,
        headers: {
          'Content-Type': file.type,
        },
      });

      if (type === 'preview') {
        setFiles((prev) => ({
          ...prev,
          preview: resp.fileName,
        }));
        setPreviewImage(URL.createObjectURL(file));
      } else if (type === 'profile') {
        setFiles((prev) => ({ ...prev, profile: resp.fileName }));
        setProfileImage(URL.createObjectURL(file));
      } else {
        setFiles((prev) => ({
          ...prev,
          additional: [...prev.additional, resp.fileName],
        }));
        setAdditionalImages((prev) => [...prev, URL.createObjectURL(file)]);
      }
      setIsLoading(false);
    } catch (error: any) {
      notifications.show({
        title: 'Something went wrong',
        color: 'red',
        mt: '100px',
        message: error.message || 'An error occurred, please try again later.',
      });
      console.log(error);
      setIsLoading(false);
    }
  };
  const handleSubmitProfileImage = async () => {
    setIsLoading(true);
    try {
      const payload = {
        image: files.profile,
      };
      const resp = await apiProfile(id as string, payload);
      notifications.show({
        title: 'Profile Updated',
        color: 'green',
        mt: '100px',
        message: 'Profile Image successfully Updated',
      });
    } catch (error: any) {
      notifications.show({
        title: 'Something went wrong',
        color: 'red',
        mt: '100px',
        message: error?.message || 'An error occurred, please try again later.',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const removeAdditionalImage = (index: number) => {
    setAdditionalImages((prev) => prev.filter((_, i) => i !== index));
    setFiles((prev) => ({
      ...prev,
      additional: prev.additional.filter((_, i) => i !== index),
    }));
  };

  const [files, setFiles] = useState<{
    preview: string;
    additional: string[];
    profile: string;
  }>({
    preview: selectedArtwork?.previewImage || '',
    additional: selectedArtwork?.images || [],
    profile: selectedArtwork?.image || '',
  });

  const closeModal = () => {
    setIsFormView(false);
    setEditingWork(false);
    setPreviewImage(null);
    setArtworkImages([]);
    setViewWork(false);
    form.reset();
    setFiles({ preview: '', additional: [], profile: '' });
  };

  const handleViewWorks = (artwork: Work) => {
    setSelectedArtwork(artwork);
    setArtworkDetail(true);
    setSelectedImage(artwork.previewImage);
    setViewWork(true);
  };

  const buildEditPayload = async (
    formValues: any,
    selectedArtwork: any,
    files: any
  ) => {
    const payload: any = {};

    // Compare and add all form fields if they've changed
    const fieldsToCheck = [
      'title',
      'year',
      'dimensionLengthInCM',
      'dimensionWidthInCM',
    ];

    fieldsToCheck.forEach((field) => {
      if (formValues[field] !== selectedArtwork[field]) {
        payload[field] = formValues[field];
      }
    });

    // Handle materials with proper add/remove logic
    const formMaterials = Array.isArray(formValues.materials)
      ? formValues.materials
      : [];

    const selectedMaterials = Array.isArray(selectedArtwork.materials)
      ? selectedArtwork.materials
      : [];

    const materialsToAdd = formMaterials.filter(
      (mat: string) => !selectedMaterials.includes(mat)
    );

    const materialsToRemove = selectedMaterials.filter(
      (mat: string) => !formMaterials.includes(mat)
    );

    if (materialsToAdd.length > 0) {
      payload.materialsToBeAdded = materialsToAdd;
    }

    if (materialsToRemove.length > 0) {
      payload.materialsToBeRemoved = materialsToRemove;
    }

    // Handle categories with proper add/remove logic
    const formCategories = Array.isArray(formValues.categories)
      ? formValues.categories
      : typeof formValues.categories === 'string'
      ? [formValues.categories]
      : [];

    const selectedCategories = Array.isArray(selectedArtwork.categories)
      ? selectedArtwork.categories
      : [];

    const categoriesToAdd = formCategories.filter(
      (cat: string) => !selectedCategories.includes(cat)
    );

    const categoriesToRemove = selectedCategories.filter(
      (cat: string) => !formCategories.includes(cat)
    );

    if (categoriesToAdd.length > 0) {
      payload.categoriesToBeAdded = categoriesToAdd;
    }

    if (categoriesToRemove.length > 0) {
      payload.categoriesToBeRemoved = categoriesToRemove;
    }

    // Handle image changes
    if (files.preview && files.preview !== selectedArtwork.previewImage) {
      payload.previewImage = files.preview;
    }

    if (files.additional) {
      const addedImages = files.additional.filter(
        (img: string) => !selectedArtwork.images.includes(img)
      );
      const removedImages = selectedArtwork.images.filter(
        (img: string) => !files.additional.includes(img)
      );
      if (addedImages?.length > 0) {
        payload.imagesToBeAdded = addedImages;
      }
      if (removedImages?.length > 0) {
        payload.imagesToBeRemoved = removedImages;
      }
    }

    return {
      payload,
      hasArtistNameChanged:
        formValues.artistName !== selectedArtwork?.artist._id,
    };
  };
  const buildCreatePayload = (formValues: any, files: any) => {
    const categories = Array.isArray(formValues.categories)
      ? formValues.categories
      : typeof formValues.categories === 'string'
      ? [formValues.categories]
      : [];

    return {
      ...formValues,
      categories,
      previewImage: files.preview,
      images: files.additional,
    };
  };

  // Update the handleSubmit function to use the new payload structure
  const handleSubmit = async () => {
    const validationResult = form.validate();
    if (validationResult.hasErrors) {
      console.log('Form has errors', validationResult.errors);
      return;
    }

    if (!files.preview || files.additional?.length === 0) {
      notifications.show({
        title: 'Missing Images',
        color: 'red',
        mt: '100px',
        message: 'Please upload both preview and additional images.',
      });
      return;
    }

    try {
      if (editingArtwork) {
        const { payload, hasArtistNameChanged } = await buildEditPayload(
          form.values,
          selectedArtwork,
          files
        );
        // Make separate API call for artist name change if needed
        if (hasArtistNameChanged) {
          await apiUpdateArtistWorkName(
            selectedArtwork?._id as string,
            form.values.artistName
          );
        }
        editArtwork(selectedArtwork?._id as string, payload);
      } else {
        const createPayload = buildCreatePayload(form.values, files);
        createArtwork(createPayload, id as string);
      }
      closeModal();
      getSingleArtist(id as string);
    } catch (error: any) {
      notifications.show({
        title: 'Something went wrong',
        color: 'red',
        mt: '100px',
        message: error?.message || 'An error occurred, please try again later.',
      });
    }
  };

  return (
    <section className={style.main}>
      <div className='mb-16 '>
        <Tabs color='#DA3400' defaultValue='artworks'>
          <Tabs.List className='mb-7 flex justify-between'>
            <Tabs.Tab value='artworks' className='px-0 '>
              Artworks
            </Tabs.Tab>
            <Tabs.Tab value='biography'>Biography</Tabs.Tab>
            <Tabs.Tab value='exhibitions'>Exhibitions</Tabs.Tab>
            <Tabs.Tab value='profile'>Profile</Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value='artworks' className='mt-10'>
            <div className='flex justify-between mt-5 mb-10'>
              <div className='flex  items-center '>
                <button
                  className='mr-4 text-xs font-normal flex'
                  onClick={
                    isFormView ? closeModal : viewWork ? closeModal : goback
                  }>
                  <span className='my-auto ml- mr-2'>
                    <FaArrowLeft />
                  </span>
                  Go Back
                </button>

                <h1 className=''>
                  <span className='text-sm font-normal'>Artist</span> /{' '}
                  <span className={viewWork ? '' : 'font-bold'}>
                    {singleArtist?.firstName} {singleArtist?.lastName}
                  </span>
                  {viewWork ? (
                    <span className='font-bold '> / Artwork</span>
                  ) : null}
                </h1>
              </div>
              {isFormView ? null : (
                <button
                  className='rounded-md py-2 px-5 bg-[#DA3400] text-white'
                  onClick={createModal}>
                  Add Artwork
                </button>
              )}
            </div>

            {/* Main Content */}
            {isFormView ? (
              <ArtworkForm
                form={form}
                isLoading={isLoading}
                isEditing={editingArtwork}
                artists={artists}
                previewImage={previewImage}
                additionalImages={additionalImages}
                onPreviewImageUpload={handlePreviewImageUpload}
                onAdditionalImagesUpload={handleOtherImages}
                onRemoveAdditionalImage={removeAdditionalImage}
                onSubmit={handleSubmit}
              />
            ) : artworkDetail && selectedArtwork ? (
              <ArtworkDetail
                edit={editModal}
                artwork={selectedArtwork}
                selectedImage={selectedImage}
                onImageSelect={(img) => setSelectedImage(img)}
              />
            ) : (
              <ArtworkGrid
                artworks={artistWork}
                currentPage={currentPage}
                itemsPerPage={itemsPerPage}
                loading={loading}
                pageChanging={pageChanging}
                onPageChange={handlePageChange}
                onViewArtwork={handleViewWorks}
              />
            )}
          </Tabs.Panel>

          <Tabs.Panel value='biography'>
            <div className='flex items-center mb-4 mt-3'>
              <button
                className='mr-4 text-xs font-normal flex'
                onClick={goback}>
                <span className='my-auto ml- mr-2'>
                  <FaArrowLeft />
                </span>
                Go Back
              </button>
              <h1 className='font-bold text-lg'>
                <span className='text-sm font-normal'>Artist</span> /{' '}
                {singleArtist?.firstName} {singleArtist?.lastName}
              </h1>
            </div>
            <div className='p-5 bg-white rounded-lg shadow-[0_2px_40px_-1px_rgba(0,0,0,0.1)] relative'>
              <LoadingOverlay
                visible={isLoading}
                zIndex={1000}
                overlayProps={{ radius: 'sm', blur: 2 }}
                loaderProps={{ color: 'red', type: 'bars' }}
              />
              <div className='flex flex-col text-center mt-3 mb-5'>
                <Avatar
                  src={profileImage || singleArtist?.image || ''}
                  alt='artist image'
                  className='w-28 h-28 mx-auto cursor-pointer'
                  onClick={() => fileInputRefBio.current?.click()}
                />
                <p
                  className='text-[#DA3400] text-sm my-2 font-bold cursor-pointer'
                  onClick={() => fileInputRefBio.current?.click()}>
                  Change Artist's Profile Image
                </p>
                <input
                  ref={fileInputRefBio}
                  type='file'
                  accept='image/*'
                  onChange={handleProfileImageUpload}
                  className='hidden'
                />
              </div>
              <p>{singleArtist?.fullBiography}</p>
              <div className='flex justify-end mt-10'>
                <button
                  type='submit'
                  disabled={loading}
                  className={`px-5 mt-5 bg-[#DA3400] text-white py-3 rounded-lg font-bold ${
                    loading ? 'cursor-disable' : 'cursor-pointer'
                  }`}
                  onClick={() => handleSubmitProfileImage()}>
                  Save Changes
                </button>
              </div>
            </div>
          </Tabs.Panel>
          <Tabs.Panel value='exhibitions'>
            <Exhibitions artistExhb={artistExhb} fetchExhb={getSingleArtist} />
          </Tabs.Panel>
          <Tabs.Panel value='profile'>
            <Profile profile={singleArtist as Artist} />
          </Tabs.Panel>
        </Tabs>
      </div>
    </section>
  );
};

export default ArtistDetails;
