import { useState } from "react";
import { notifications } from "@mantine/notifications";
import {
  apiArtwork,
  apiEditArtwork,
  apiGetAllArtist,
  apiGetAllArtwork,
  apiSingleArtist,
} from "../api/ApiServices";

export const useArtwork = () => {
  const [loading, setLoading] = useState(false);
  const [artworks, setArtworks] = useState<Work[]>([]);
  const [artists, setArtists] = useState<Artist[]>([]);
  const [artistExhb, setArtistExhb] = useState<Exhibition[]>([]);
  const [artistWork, setArtistWork] = useState<Work[]>([]);
  const [singleArtist, setSingleArtist] = useState<Artist>();

  const fetchAllArtworks = async () => {
    setLoading(true);
    try {
      const response = await apiGetAllArtwork();

      const sortedArtworks = response.works.sort((a, b) => {
        return (
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
      });
      setArtworks(sortedArtworks);
    } catch (error: any) {
      notifications.show({
        title: "Error",
        message: error.message,
        color: "red",
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchAllArtists = async () => {
    setLoading(true);
    try {
      const response = await apiGetAllArtist();
      setArtists(response.artists);
    } catch (error: any) {
      notifications.show({
        title: "Error",
        message: error.message,
        color: "red",
      });
    } finally {
      setLoading(false);
    }
  };

  // get single artist
  const getSingleArtist = async (id: string) => {
    setLoading(true);
    try {
      const resp = await apiSingleArtist(id as string);
      setSingleArtist(resp.artist);
      setArtistExhb(resp.artist.exhibitions as Exhibition[]);
      setArtistWork(resp.artist.works as Work[]);
      setLoading(false);
    } catch (error: any) {
      notifications.show({
        title: "Something went wrong",
        color: "red",
        mt: "100px",
        message: error?.message || "An error occurred, please try again later.",
      });
      setLoading(false);
    }
  };
  const createArtwork = async (data: Work, id: string) => {
    setLoading(true);
    try {
      const response = await apiArtwork(data, id);
      notifications.show({
        title: "Success",
        message: "Artwork created successfully",
        color: "green",
        mt: "100px",
      });
      await fetchAllArtworks();
      await getSingleArtist(id);
      return response.updatedWork;
    } catch (error: any) {
      notifications.show({
        title: "Error",
        message: error.message,
        color: "red",
        mt: "100px",
      });
    } finally {
      setLoading(false);
    }
  };

  const editArtwork = async (id: string, data: Partial<Work>) => {
    setLoading(true);
    try {
      const response = await apiEditArtwork(id, data);
      notifications.show({
        title: "Success",
        message: "Artwork updated successfully",
        color: "green",
        mt: "100px",
      });
      await fetchAllArtworks();
      return response.updatedWork;
    } catch (error: any) {
      notifications.show({
        title: "Error",
        message: error.message,
        color: "red",
        mt: "100px",
      });
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    artworks,
    artists,
    artistExhb,
    artistWork,
    singleArtist,
    fetchAllArtworks,
    fetchAllArtists,
    createArtwork,
    editArtwork,
    getSingleArtist,
  };
};
