import { notifications } from '@mantine/notifications';
import {
  apiUpdateArtistWorkName,
  getPresignedUrl,
} from '../../api/ApiServices';
import style from '../../styles/style.module.css';
import { useEffect, useState } from 'react';
import { Loader } from '@mantine/core';
import ArtworkDetail from '../artist/details/ArtworkDetail';
import { useForm } from '@mantine/form';
import { FaArrowLeft } from 'react-icons/fa6';
import dayjs from 'dayjs';
import { ArtworkForm, ArtworkGrid } from '../../utils/ArtworkGrid';
import { useArtwork } from '../../utils/useArtwork';

const Index = () => {
  const [artworkDetail, setArtworkDetail] = useState(false);
  const [selectedWork, setSelectedWork] = useState<Work>();
  const [selectedImage, setSelectedImage] = useState<string>('');
  const [isFormView, setIsFormView] = useState(false);
  const [editingArtwork, setEditingArtwork] = useState(false);
  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const [additionalImages, setAdditionalImages] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [viewWork, setViewWork] = useState(false);
  const [artworkImages, setArtworkImages] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(9);
  const [pageChanging, setPageChanging] = useState(false);

  const {
    loading,
    artworks,
    artists,
    fetchAllArtworks,
    fetchAllArtists,
    editArtwork,
    createArtwork,
  } = useArtwork();

  useEffect(() => {
    fetchAllArtworks();
    fetchAllArtists();
  }, []);

  const createModal = () => {
    setIsFormView(true);
  };

  const editModal = () => {
    setIsFormView(true);
    setEditingArtwork(true);
  };

  const closeModal = () => {
    setIsFormView(false);
    setEditingArtwork(false);
    setPreviewImage(null);
    setArtworkImages([]);
    setArtworkDetail(false);
    form.reset();
    setViewWork(false);
    setFiles({ preview: '', additional: [] });
  };

  const handlePageChange = (newPage: number) => {
    setPageChanging(true);
    setCurrentPage(newPage);
    setTimeout(() => {
      setPageChanging(false);
    }, 500);
  };

  const form = useForm({
    initialValues: {
      artistName: '',
      title: '',
      year: '',
      categories: '',
      materials: [] as string[],
      dimensionLengthInCM: '',
      dimensionWidthInCM: '',
    },
    validate: {
      artistName: (value) => (value.trim() ? null : 'Artist Name is required'),
      title: (value) => (value.trim() ? null : 'Artwork Title is required'),
      year: (value) => (value.trim() ? null : 'Year of Creation is required'),
      categories: (value) =>
        value.trim() ? null : 'At least one category is required',
      materials: (value) =>
        value.length > 0 ? null : 'At least one material is required',
      dimensionLengthInCM: (value) =>
        value ? null : 'Artwork Dimension (Length) is required',
      dimensionWidthInCM: (value) =>
        value ? null : 'Artwork Dimension (Width) is required',
    },
  });

  useEffect(() => {
    if (selectedWork) {
      setIsLoading(true);

      form.setValues({
        artistName: selectedWork?.artist?._id || '',
        title: selectedWork.title || '',
        year: selectedWork.year
          ? dayjs(selectedWork.year).format('YYYY-MM-DD')
          : '',
        categories: selectedWork.categories[0] || '',

        materials: selectedWork.materials || [],
        dimensionLengthInCM: selectedWork.dimensionLengthInCM || '',
        dimensionWidthInCM: selectedWork.dimensionWidthInCM || '',
      });
      if (selectedWork.previewImage) {
        setPreviewImage(selectedWork.previewImage);
        setFiles((prev) => ({
          ...prev,
          preview: selectedWork.previewImage,
        }));
      }

      // Set the additional images
      if (selectedWork?.images && selectedWork?.images?.length > 0) {
        setAdditionalImages(selectedWork.images);
        setFiles((prev) => ({
          ...prev,
          additional: selectedWork.images,
        }));

        setIsLoading(false);
      }
    }
  }, [selectedWork]);

  const handlePreviewImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        if (e.target?.result && typeof e.target.result === 'string') {
          setPreviewImage(e.target.result);
        }
      };
      reader.readAsDataURL(file);
      uploadImage(file, 'preview');
    }
  };

  const handleOtherImages = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      for (const file of Array.from(files)) {
        try {
          await uploadImage(file, 'additional');
        } catch (error) {
          console.error('Error uploading image:', error);
          notifications.show({
            title: 'Upload Error',
            color: 'red',
            message: 'An error occurred while uploading the image.',
          });
        }
      }
    }
  };

  const removeAdditionalImage = (index: number) => {
    setAdditionalImages((prev) => prev.filter((_, i) => i !== index));
    setFiles((prev) => ({
      ...prev,
      additional: prev.additional.filter((_, i) => i !== index),
    }));
  };

  const [files, setFiles] = useState<{
    preview: string;
    additional: string[];
  }>({
    preview: selectedWork?.previewImage || '',
    additional: selectedWork?.images || [],
  });

  const uploadImage = async (file: File, type: 'preview' | 'additional') => {
    setIsLoading(true);
    try {
      const resp = await getPresignedUrl(file.name);
      console.log(resp);
      const uploadResponse = await fetch(resp.url, {
        method: 'PUT',
        body: file,
        headers: {
          'Content-Type': file.type,
        },
      });
      if (!uploadResponse.ok) {
        notifications.show({
          title: 'Something went wrong',
          color: 'red',
          mt: '100px',
          message: 'An error occurred, please try again later.',
        });
        return;
      }
      if (type === 'preview') {
        setFiles((prev) => ({
          ...prev,
          preview: resp.fileName,
        }));
        setPreviewImage(URL.createObjectURL(file));
      } else {
        setFiles((prev) => ({
          ...prev,
          additional: [...prev.additional, resp.fileName],
        }));
        setAdditionalImages((prev) => [...prev, URL.createObjectURL(file)]);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const buildEditPayload = async (
    formValues: any,
    selectedArtwork: any,
    files: any
  ) => {
    const payload: any = {};

    // Compare and add all form fields if they've changed
    const fieldsToCheck = [
      'title',
      'year',
      'dimensionLengthInCM',
      'dimensionWidthInCM',
    ];

    fieldsToCheck.forEach((field) => {
      if (formValues[field] !== selectedArtwork[field]) {
        payload[field] = formValues[field];
      }
    });

    // Handle materials with proper add/remove logic
    const formMaterials = Array.isArray(formValues.materials)
      ? formValues.materials
      : [];

    const selectedMaterials = Array.isArray(selectedArtwork.materials)
      ? selectedArtwork.materials
      : [];

    const materialsToAdd = formMaterials.filter(
      (mat: string) => !selectedMaterials.includes(mat)
    );

    const materialsToRemove = selectedMaterials.filter(
      (mat: string) => !formMaterials.includes(mat)
    );

    if (materialsToAdd.length > 0) {
      payload.materialsToBeAdded = materialsToAdd;
    }

    if (materialsToRemove.length > 0) {
      payload.materialsToBeRemoved = materialsToRemove;
    }

    // Handle categories with proper add/remove logic
    const formCategories = Array.isArray(formValues.categories)
      ? formValues.categories
      : typeof formValues.categories === 'string'
      ? [formValues.categories]
      : [];

    const selectedCategories = Array.isArray(selectedArtwork.categories)
      ? selectedArtwork.categories
      : [];

    const categoriesToAdd = formCategories.filter(
      (cat: string) => !selectedCategories.includes(cat)
    );

    const categoriesToRemove = selectedCategories.filter(
      (cat: string) => !formCategories.includes(cat)
    );

    if (categoriesToAdd.length > 0) {
      payload.categoriesToBeAdded = categoriesToAdd;
    }

    if (categoriesToRemove.length > 0) {
      payload.categoriesToBeRemoved = categoriesToRemove;
    }

    // Handle image changes
    if (files.preview && files.preview !== selectedArtwork.previewImage) {
      payload.previewImage = files.preview;
    }

    if (files.additional) {
      const addedImages = files.additional.filter(
        (img: string) => !selectedArtwork.images.includes(img)
      );
      const removedImages = selectedArtwork.images.filter(
        (img: string) => !files.additional.includes(img)
      );
      if (addedImages?.length > 0) {
        payload.imagesToBeAdded = addedImages;
      }
      if (removedImages?.length > 0) {
        payload.imagesToBeRemoved = removedImages;
      }
    }

    return {
      payload,
      hasArtistNameChanged:
        formValues.artistName !== selectedArtwork?.artist?._id,
    };
  };

  // Update the handleSubmit function to use the new payload structure
  const handleSubmit = async () => {
    const validationResult = form.validate();
    if (validationResult.hasErrors) {
      console.log('Form has errors', validationResult.errors);
      return;
    }

    if (!files.preview || files.additional?.length === 0) {
      notifications.show({
        title: 'Missing Images',
        color: 'red',
        mt: '100px',
        message: 'Please upload both preview and additional images.',
      });
      return;
    }

    try {
      if (editingArtwork) {
        const { payload, hasArtistNameChanged } = await buildEditPayload(
          form.values,
          selectedWork,
          files
        );
        // Make separate API call for artist name change if needed
        if (hasArtistNameChanged) {
          await apiUpdateArtistWorkName(
            selectedWork?._id as string,
            form.values.artistName
          );
        }
        editArtwork(selectedWork?._id as string, payload);
      }
      closeModal();
      fetchAllArtworks();
    } catch (error: any) {
      notifications.show({
        title: 'Something went wrong',
        color: 'red',
        mt: '100px',
        message: error?.message || 'An error occurred, please try again later.',
      });
    }
  };

  const handleViewWorks = (artwork: Work) => {
    setArtworkDetail(true);
    setSelectedImage(artwork.previewImage);
    setSelectedWork(artwork);
  };

  if (loading && !isFormView) {
    return (
      <div className='flex justify-center text-center items-center h-[70vh]'>
        <Loader size={40} />
      </div>
    );
  }

  if (artworks?.length === 0 && !isFormView) {
    return (
      <div className='flex justify-center h-[80vh] my-auto'>
        <div className='my-auto text-center'>
          <p className='font-bold text-xl mb-3 mx-auto'>
            No artwork created yet.
          </p>
          <button
            onClick={createModal}
            className='px-32 bg-[#DA3400] text-white py-2 rounded-lg font-bold'>
            Add artwork
          </button>
        </div>
      </div>
    );
  }

  return (
    <section className={style.main}>
      {/* Header Section */}
      {artworkDetail || isFormView ? (
        <div className='flex items-center mb-6'>
          <button
            className='mr-4 text-xs font-normal flex'
            onClick={closeModal}>
            <span className='my-auto ml- mr-2'>
              <FaArrowLeft />
            </span>
            Go Back
          </button>
          <h1>
            <span className='text-sm font-normal'>Artwork</span> /{' '}
            <span className={viewWork ? '' : 'font-bold'}>
              {selectedWork?.title}
            </span>
            {viewWork && <span className='font-bold'> / Artwork</span>}
          </h1>
        </div>
      ) : (
        <h1 className='font-bold text-lg mt-3 mb-8'>All Artworks</h1>
      )}

      {/* Main Content */}
      {isFormView ? (
        <ArtworkForm
          form={form}
          isLoading={isLoading}
          isEditing={editingArtwork}
          artists={artists}
          previewImage={previewImage}
          additionalImages={additionalImages}
          onPreviewImageUpload={handlePreviewImageUpload}
          onAdditionalImagesUpload={handleOtherImages}
          onRemoveAdditionalImage={removeAdditionalImage}
          onSubmit={handleSubmit}
        />
      ) : artworkDetail && selectedWork ? (
        <ArtworkDetail
          edit={editModal}
          artwork={selectedWork}
          selectedImage={selectedImage}
          onImageSelect={(img) => setSelectedImage(img)}
        />
      ) : (
        <ArtworkGrid
          artworks={artworks}
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          loading={loading}
          pageChanging={pageChanging}
          onPageChange={handlePageChange}
          onViewArtwork={handleViewWorks}
        />
      )}
    </section>
  );
};

export default Index;
